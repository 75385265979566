import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import TransitionLink from 'gatsby-plugin-transition-link'

import imagesLoaded from 'imagesloaded'
import gsap from 'gsap'

import { css } from '@emotion/core'
import 'twin.macro'
import '../styles/lazyload.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import usePrevious from '../hooks/use-previous'

import { AnimateIn } from './animate-in'
import CircleButton from '../components/circle-button'
import SiteFoot from '../components/site-foot'

import { outTransition, outDuration } from '../transitions/fade'

import {
  inTransition,
  inDuration,
} from '../transitions/homepage-to-project-transition'

const slideInTimings = [
  {
    image1: {
      offset: 80,
      duration: 1.5,
    },
    image2: {
      offset: -350,
      duration: 1.8,
    },
    image3: {
      offset: 280,
      duration: 1.4,
    },
  },
  {
    image1: {
      offset: 200,
      duration: 1.5,
    },
    image2: {
      offset: -350,
      duration: 1.8,
    },
    image3: {
      offset: -100,
      duration: 1.4,
    },
  },
  {
    image1: {
      offset: 40,
      duration: 1.5,
    },
    image2: {
      offset: -350,
      duration: 1.8,
    },
    image3: {
      offset: -170,
      duration: 1.4,
    },
  },
  {
    image1: {
      offset: 0,
      duration: 1.5,
    },
    image2: {
      offset: -350,
      duration: 1.8,
    },
    image3: {
      offset: 370,
      duration: 1.4,
    },
  },
  {
    image1: {
      offset: -100,
      duration: 1.5,
    },
    image2: {
      offset: -380,
      duration: 1.8,
    },
    image3: {
      offset: 0,
      duration: 1.4,
    },
  },
]

const HomepageListView = ({ projects }) => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0)
  const prevProjectIndex = usePrevious(currentProjectIndex)

  const projectElRefs = useRef([React.createRef(), React.createRef()])
  const isSlidingRef = useRef(true)
  const playingTlRef = useRef(null)

  let supportsWheel = false
  let moveDistance = window.innerHeight
  let touchStartAmount = null

  useEffect(() => {
    // only start when the first projects images are loaded
    imagesLoaded(projectElRefs.current[0], () => {
      const titleEls = document.querySelectorAll('[data-gsap="project-title"]')
      playingTlRef.current = gsap.timeline({
        onComplete() {
          playingTlRef.current = null
        },
      })
      playingTlRef.current.set(titleEls, {
        y: moveDistance,
      })
      playingTlRef.current.add(getSlideInTl(0, 'up', getRandomSlideInTimings()))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener('wheel', handleScroll)
    document.addEventListener('mousewheel', handleScroll)
    document.addEventListener('DOMMouseScroll', handleScroll)
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchend', handleTouchEnd)
    window.addEventListener('resize', handleResize)

    if (typeof prevProjectIndex !== 'undefined') {
      if (playingTlRef.current) {
        playingTlRef.current.kill()
      }

      playingTlRef.current = gsap.timeline({
        onComplete() {
          playingTlRef.current = null
        },
      })

      if (
        (prevProjectIndex < currentProjectIndex ||
          (prevProjectIndex === projects.length - 1 &&
            currentProjectIndex === 0)) &&
        !(prevProjectIndex === 0 && currentProjectIndex === projects.length - 1)
      ) {
        // going forwards
        playingTlRef.current.add(getSlideOutTl(prevProjectIndex, 'up'))
        playingTlRef.current.add(
          getSlideInTl(currentProjectIndex, 'up', getRandomSlideInTimings()),
          '-=0.6'
        )
      } else {
        // going backwards
        playingTlRef.current.add(getSlideOutTl(prevProjectIndex, 'down'))
        playingTlRef.current.add(
          getSlideInTl(currentProjectIndex, 'down', getRandomSlideInTimings()),
          '-=0.6'
        )
      }

      playingTlRef.current.play()
    }

    return () => {
      document.removeEventListener('wheel', handleScroll)
      document.removeEventListener('mousewheel', handleScroll)
      document.removeEventListener('DOMMouseScroll', handleScroll)
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchend', handleTouchEnd)
      window.removeEventListener('resize', handleResize)
    }
  }, [currentProjectIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTouchStart = e => {
    if (!isSlidingRef.current) {
      touchStartAmount = e.touches[0].clientY
    }
  }

  const handleTouchEnd = e => {
    if (!isSlidingRef.current) {
      const touchEndAmount = e.changedTouches[0].clientY
      if (touchStartAmount < touchEndAmount - 5) {
        goToPrev()
      } else if (touchStartAmount > touchEndAmount + 5) {
        goToNext()
      }
      touchStartAmount = null
    }
  }

  const handleScroll = e => {
    const delta = (e.deltaY || -e.wheelDelta || e.detail) >> 10 || 1

    if (!isSlidingRef.current) {
      if (e.type === 'wheel') {
        supportsWheel = true
      } else if (supportsWheel) {
        return
      }

      if (delta < 0) {
        goToPrev()
      } else {
        goToNext()
      }
    }
  }

  const handleResize = () => {
    moveDistance = 2 * (window.innerHeight / 3)
  }

  const goToNext = () => {
    isSlidingRef.current = true
    setCurrentProjectIndex((currentProjectIndex + 1) % projects.length)
  }

  const goToPrev = () => {
    isSlidingRef.current = true
    let newProjectIndex = (currentProjectIndex - 1) % projects.length
    if (newProjectIndex < 0) {
      newProjectIndex = projects.length - 1
    }
    setCurrentProjectIndex(newProjectIndex)
  }

  const getSlideOutTl = (projectIndex, direction) => {
    const {
      containerEl,
      titleEl,
      image1El,
      image2El,
      image3El,
      buttonEl,
    } = getElements(projectIndex)

    const y = direction === 'up' ? -moveDistance : moveDistance

    const tl = gsap.timeline()
    tl.set([containerEl, image1El, image2El, image3El], { autoAlpha: 1 })
    tl.add('start')
    tl.to(
      buttonEl,
      {
        autoAlpha: 0,
        duration: 0.8,
        ease: 'power2.out',
      },
      'start'
    )
    tl.to(
      titleEl,
      {
        y,
        duration: 1.2,
        ease: 'power2.in',
      },
      'start'
    )

    tl.to(
      image1El,
      {
        y,
        duration: 1.3,
        ease: 'power2.in',
      },
      'start'
    )
    tl.to(
      image2El,
      {
        y,
        duration: 1,
        ease: 'power2.in',
      },
      'start'
    )
    tl.to(
      image3El,
      {
        y,
        duration: 1.5,
        ease: 'power2.in',
      },
      'start'
    )
    tl.set(containerEl, { autoAlpha: 0, display: 'none' })

    return tl
  }

  const getSlideInTl = (projectIndex, direction, timings) => {
    const {
      containerEl,
      titleEl,
      image1El,
      image2El,
      image3El,
      buttonEl,
    } = getElements(projectIndex)

    const tl = gsap.timeline()
    tl.set([titleEl, image1El, image2El, image3El], {
      y: direction === 'up' ? moveDistance : -moveDistance,
    })
    tl.set(containerEl, { autoAlpha: 1, display: 'flex' })
    tl.add('start')
    tl.to(
      titleEl,
      {
        y: 0,
        duration: 1.5,
        ease: 'power2.out',
      },
      'start'
    )
    tl.to(
      image1El,
      {
        y: timings.image1.offset,
        duration: timings.image1.duration,
        ease: 'power2.out',
      },
      'start+=0.3'
    )
    tl.to(
      image2El,
      {
        y: timings.image2.offset,
        duration: timings.image2.duration,
        ease: 'power2.out',
      },
      'start+=0.2'
    )
    tl.to(
      image3El,
      {
        y: timings.image3.offset,
        duration: timings.image3.duration,
        ease: 'power2.out',
      },
      'start+=0.4'
    )
    tl.add(() => {
      isSlidingRef.current = false
    }, '-=1')
    tl.to(buttonEl, {
      autoAlpha: 1,
      duration: 0.8,
      ease: 'power2.out',
    })
    return tl
  }

  const getRandomSlideInTimings = () => {
    return slideInTimings[Math.floor(Math.random() * slideInTimings.length)]
  }

  const getElements = projectIndex => {
    const containerEl = document.querySelector(
      `[data-gsap="project-container"][data-index="${projectIndex}"]`
    )

    return {
      containerEl,
      titleEl: containerEl.querySelector('[data-gsap="project-title"]'),
      image1El: containerEl.querySelector(
        '[data-gsap="project-image"][data-index="0"]'
      ),
      image2El: containerEl.querySelector(
        '[data-gsap="project-image"][data-index="1"]'
      ),
      image3El: containerEl.querySelector(
        '[data-gsap="project-image"][data-index="2"]'
      ),
      buttonEl: containerEl.querySelector('[data-gsap="project-button"]'),
    }
  }

  return (
    <div
      css={css`
        &.fade-enter {
          opacity: 0;
        }
        &.fade-enter-active {
          opacity: 1;
          transition: opacity 400ms;
        }
        &.fade-exit {
          opacity: 1;
        }
        &.fade-exit-active {
          opacity: 0;
          transition: opacity 400ms;
        }
      `}
    >
      <div tw="absolute left-0 top-0 w-full h-full overflow-hidden z-10 hidden md:block">
        {projects.map((project, index) => (
          <div
            key={index}
            tw="absolute left-0 top-0 w-full h-full flex flex-col justify-center opacity-0"
            data-gsap="project-container"
            data-index={index}
            ref={projectElRefs.current[index]}
          >
            <div tw="w-full flex items-start justify-center left-0">
              {project.homepageListViewImages.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  alt={image.altText}
                  tw="rounded h-auto mr-4 last:mr-0 w-5/12"
                  css={css`
                    width: 600px;
                    top: 100%;
                    z-index: ${imageIndex === 1 ? 10 : 30};
                  `}
                  sizes="100vw"
                  data-gsap="project-image"
                  data-index={imageIndex}
                  src={image.optimisedImages.src}
                />
              ))}
              <div tw="absolute left-0 top-0 w-full h-full flex justify-center items-center">
                <div
                  tw="z-20 text-center pointer-events-none"
                  css={css`
                    top: -100%;
                  `}
                >
                  <h2
                    tw="block text-4xl lg:text-5xl xl:text-6xl text-black font-normal mb-8"
                    data-gsap="project-title"
                    css={css`
                      max-width: 500px;
                      line-height: 1.1;

                      @media (min-width: 1024px) {
                        max-width: 600px;
                      }

                      @media (min-width: 1280px) {
                        max-width: 707px;
                      }
                    `}
                  >
                    {project.title}
                  </h2>
                  <div tw="opacity-0 text-center" data-gsap="project-button">
                    <CircleButton
                      bgColour="white"
                      label="View project"
                      inTransition={inTransition}
                      inDuration={inDuration}
                      to={project.uri}
                    >
                      <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                    </CircleButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div tw="md:hidden mt-24 px-4">
        <>
          {projects.map((project, index) => (
            <AnimateIn key={index}>
              <div tw="mb-12 text-center">
                <>
                  <TransitionLink
                    exit={{ trigger: outTransition, length: outDuration }}
                    entry={{
                      trigger: inTransition,
                      length: inDuration,
                      delay: outDuration,
                    }}
                    to={project.uri}
                  >
                    {project.homepageListViewImages.length ? (
                      <img
                        alt={project.homepageListViewImages[0].altText}
                        tw="rounded w-full h-auto mb-4"
                        sizes="100vw"
                        srcSet={
                          project.homepageListViewImages[0].optimisedImages
                            .srcset
                        }
                        src={
                          project.homepageListViewImages[0].optimisedImages.src
                        }
                      />
                    ) : null}
                    <h2
                      tw="block text-center text-3xl text-black font-normal mb-4 leading-snug"
                      css={css`
                        line-height: 1.2;
                      `}
                    >
                      {project.title}
                    </h2>
                  </TransitionLink>
                  <div tw="mx-auto">
                    <CircleButton
                      bgColour="white"
                      label="View project"
                      inTransition={inTransition}
                      inDuration={inDuration}
                      to={project.uri}
                    >
                      <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                    </CircleButton>
                  </div>
                </>
              </div>
            </AnimateIn>
          ))}
          <div tw="md:hidden">
            <SiteFoot />
          </div>
        </>
      </div>
    </div>
  )
}

HomepageListView.propTypes = {
  projects: PropTypes.array,
}

HomepageListView.defaultProps = {
  projects: [],
}

export default HomepageListView
